import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPhone,
    faAt,
    faLocationDot,
    faPaperPlane,

} from "@fortawesome/free-solid-svg-icons"

function Contact() {
    return (

        <div className="flex flex-wrap place-content-center max-w-screen mx-auto p-4 bg-slate-100 text-slate-700 max-h-screen items-center dark:dark:bg-gray-800">


            <div className="flex flex-col shadow-lg rounded max-h-fit bg-slate-300 my-auto md:w-4/6 dark: shadow shadow-[#FC6C04] ">

                <div className="container mx-auto shrink-0 w-fit my-6">

                    <p className="  text-left text-3xl leading-8 font-extrabold tracking-tight"><FontAwesomeIcon icon={faPaperPlane} className="mr-4 dark:text-[#FC6C04]" />Let's Get In Touch</p>

                </div>

                <div className=" items-left mb-12">
                    <p className="p-4 ml-2 text-left text-slate-700"  > <FontAwesomeIcon icon={faPhone} className="mr-3 dark:text-[#FC6C04]" /> <a href="tel:787-565-8760">+1 787-565-8760</a></p>

                    <p className="p-4 ml-2 text-left "> <FontAwesomeIcon icon={faAt} className="mr-3 dark:text-[#FC6C04]" /> <a href="mailto:info@intelvox.com">info@intelvox.com</a></p>

                    <p className="p-4 ml-2 text-left" href="https://goo.gl/maps/TJrWfQMScktGehVXA"> <FontAwesomeIcon icon={faLocationDot} className="mr-3 dark:text-[#FC6C04]" /> <a href="https://goo.gl/maps/TJrWfQMScktGehVXA" >412 Mendez Vigo, Dorado, P.R., 00646</a></p>
                </div>
            </div>
        </div>




    )
}

export default Contact