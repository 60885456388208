import React from "react"

function Footer() {

    return (
        <footer className="">
            <div className="pt-4 h-full bg-slate-500 dark:bg-slate-700 max-w-screen mx-auto">
                <div className="pt-4 pb-4 space-x-4 flex max-w-min mx-auto items-center justify-between">
                    <a href="https://www.facebook.com/Intelvoxllc" className="text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                        <svg width="20" height="20" fill="currentColor" className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                            </path>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/intelvox/" className="text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                        <svg width="20" height="20" fill="currentColor" className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                            </path>
                        </svg>
                    </a>
                </div>
                <div className="flex justify-center items-center text-gray-400 dark:text-gray-400 mb-4">

                    <div className="container mx-auto text-center align-center">
                        <h1 className="  select-none "> Home to businesses of the future. IntelVox, All Rights Reserved.</h1>
                    </div>
                </div>
            </div>



        </footer>
    )
}

export default Footer