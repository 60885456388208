function Trusted() {


    return (

        <section className="max-w-screen bg-slate-100 dark:bg-gray-800 px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8 lg:py-20">
            <div className="max-w-4xl mx-auto text-center ">
                <h2 className="text-3xl font-extrabold leading-9 text-slate-700	 sm:text-4xl sm:leading-10 dark:text-white ">
                    Puerto Rico's innovative contact center
                </h2>
                <p className="mt-3 text-base leading-7 sm:mt-4 text-slate-700	dark:text-white ">
                    We are repeatedly chosen for our quality and service. 
                </p>
            </div>
            <div className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
                <div>
                    <p className="text-5xl font-extrabold leading-none text-slate-700	dark:text-[#FC6C04] ">
                        1000+
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-slate-700	dark:text-white ">
                        Daily Interactions
                    </p>
                </div>
                <div className="mt-10 sm:mt-0">
                    <p className="text-5xl font-extrabold leading-none text-slate-700	dark:text-[#FC6C04] ">
                        100%
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-slate-700	dark:text-white ">
                        Uptime
                    </p>
                </div>
                <div className="mt-10 sm:mt-0">
                    <p className="text-5xl font-extrabold leading-none text-slate-700	dark:text-[#FC6C04] ">
                        24/7
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-slate-700 dark:text-white 	">
                        Operation
                    </p>
                </div>
            </div>
            {/*<div className="w-52 mx-auto mt-4 p-4 flex">
        <button type="button" className="py-2 px-4  bg-gradient-to-r from-[#FC6C04] to-[#FC6C04] text-white	 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ">
            Learn More!
        </button>
    </div>*/}
        </section>

    )
}

export default Trusted