import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

function NavTripleMenu() {
  const [mobileOpen, setMobileOpen] = useState(false)
  return (
    <div className="flex items-center h-20 px-8 justify-between shadow-sm bg-white relative z-50 max-w-screen mx-auto dark:bg-slate-700 text-[#FC6C04]">
      <a className="flex-1 no-underline block h-10" href="/#">
        <img
          src="https://ivoxweb.s3.amazonaws.com/logoIvox.png"
          className="h-full"
          alt=''
        />
      </a>
      <div className="flex-none hidden md:flex md:justify-center md:h-full ">
        <a

          href="/#"
          className="text-slate-700 h-full flex items-center mx-4 px-2 border-b-2 border-black transition-colors duration-300 ease-in-out hover:text-[#FC6C04] dark:text-[#FC6C04] hover:dark:text-white"
        >Home
        </a>
      </div>
      <div className="flex-1 items-center justify-end hidden md:flex">
      </div>
      <FontAwesomeIcon
        icon={mobileOpen ? faTimes : faBars}
        onClick={() => setMobileOpen(!mobileOpen)}
        className="text-[#FC6C04] text-3xl cursor-pointer md:hidden"
      />
      {mobileOpen && (
        <div className=" text-slate-700 bg-white	absolute top-full left-0 flex flex-col w-full py-8 shadow-sm lg:hidden">
          <div className="flex-1 flex flex-col items-center text-xl">
            <a
              href="/#"
              className="no-underline px-2 py-1 my-2 font-medium border-b-2 border-black hover: text-[#FC6C04]"
            >Home
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavTripleMenu