function Features() {
    return (
        <div className="bg-white dark:bg-slate-700">
            <div className="container max-w-screen mx-auto px-6 p-6 ">
                <div className="mb-16 text-center ">
                    <h2 className="text-base text-[#FC6C04] font-semibold tracking-wide uppercase">
                        Services
                    </h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-slate-700 dark:text-white sm:text-4xl">
                        Get to know some of our services
                    </p>
                </div>
                <div className="flex flex-wrap my-12 dark:text-white ">
                    <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" /></svg>
                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                Call Center
                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            Powerful outbound and inbound solutions with flexible, and 100% customizable reporting. Let us do the heavy lifting, we provide a complete contact center experience, from recruitment to quality control, its covered.
                        </p>
                    </div>
                    <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32C114.6 32 .0137 125.1 .0137 240c0 49.59 21.39 95 56.99 130.7c-12.5 50.39-54.31 95.3-54.81 95.8C0 468.8-.5938 472.2 .6875 475.2C1.1 478.2 4.813 480 8 480c66.31 0 116-31.8 140.6-51.41C181.3 440.9 217.6 448 256 448C397.4 448 512 354.9 512 240S397.4 32 256 32zM167.3 271.9C163.9 291.1 146.3 304 121.1 304c-4.031 0-8.25-.3125-12.59-1C101.1 301.8 92.81 298.8 85.5 296.1c-8.312-3-14.06-12.66-11.09-20.97S85 261.1 93.38 264.9c6.979 2.498 14.53 5.449 20.88 6.438C125.7 273.1 135 271 135.8 266.4c1.053-5.912-10.84-8.396-24.56-12.34c-12.12-3.531-44.28-12.97-38.63-46c4.062-23.38 27.31-35.91 58-31.09c5.906 .9062 12.44 2.844 18.59 4.969c8.344 2.875 12.78 12 9.906 20.34C156.3 210.7 147.2 215.1 138.8 212.2c-4.344-1.5-8.938-2.938-13.09-3.594c-11.22-1.656-20.72 .4062-21.5 4.906C103.2 219.2 113.6 221.5 124.4 224.6C141.4 229.5 173.1 238.5 167.3 271.9zM320 288c0 8.844-7.156 16-16 16S288 296.8 288 288V240l-19.19 25.59c-6.062 8.062-19.55 8.062-25.62 0L224 240V288c0 8.844-7.156 16-16 16S192 296.8 192 288V192c0-6.875 4.406-12.1 10.94-15.18c6.5-2.094 13.71 .0586 17.87 5.59L256 229.3l35.19-46.93c4.156-5.531 11.4-7.652 17.87-5.59C315.6 179 320 185.1 320 192V288zM439.3 271.9C435.9 291.1 418.3 304 393.1 304c-4.031 0-8.25-.3125-12.59-1c-8.25-1.25-16.56-4.25-23.88-6.906c-8.312-3-14.06-12.66-11.09-20.97s10.59-13.16 18.97-10.19c6.979 2.498 14.53 5.449 20.88 6.438c11.44 1.719 20.78-.375 21.56-4.938c1.053-5.912-10.84-8.396-24.56-12.34c-12.12-3.531-44.28-12.97-38.63-46c4.031-23.38 27.25-35.91 58-31.09c5.906 .9062 12.44 2.844 18.59 4.969c8.344 2.875 12.78 12 9.906 20.34c-2.875 8.344-11.94 12.81-20.34 9.906c-4.344-1.5-8.938-2.938-13.09-3.594c-11.19-1.656-20.72 .4062-21.5 4.906C375.2 219.2 385.6 221.5 396.4 224.6C413.4 229.5 445.1 238.5 439.3 271.9z" /></svg>
                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                SMS Solutions

                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            From mass messaging to cleaning up your leads, we got you covered. We provide various SMS and MMS services as well as develop software to handle your unique tasks.
                        </p>
                    </div>
                    <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z" /></svg>
                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                Reports
                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            We provide tailor-made reports based on your specific business needs. You will have the tools for critical decision-making.</p>
                    </div>
                    <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M496 224c-79.63 0-144 64.38-144 144s64.38 144 144 144s144-64.38 144-144S575.6 224 496 224zM544 384h-54.25C484.4 384 480 379.6 480 374.3V304c0-8.836 7.164-16 16-16c8.838 0 16 7.164 16 16v48h32c8.838 0 16 7.164 16 15.1S552.8 384 544 384zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM320 368c0-19.3 3.221-37.82 8.961-55.2C311.9 307.2 293.6 304 274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512H395C349.7 480.2 320 427.6 320 368z" /></svg>

                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                After Hours
                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            With our 24 hour services, you will never miss out on a opportunity to assist your clients. We have agents ready at all times to handle every interaction.
                        </p>
                    </div>
                    <div className="w-full border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM328.1 304.1C338.3 295.6 338.3 280.4 328.1 271C319.6 261.7 304.4 261.7 295 271L200 366.1L152.1 319C143.6 309.7 128.4 309.7 119 319C109.7 328.4 109.7 343.6 119 352.1L183 416.1C192.4 426.3 207.6 426.3 216.1 416.1L328.1 304.1z" /></svg>
                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                Appointments
                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            We know how much your time means to you, from appointment confirmation to full marketing campaigns, we do it all.
                        </p>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 p-8">
                        <div className="flex items-center mb-6">
                            <svg width="20" height="20" fill="currentColor" className="h-6 w-6 text-[#FC6C04]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M466.5 83.71l-192-80C269.6 1.67 261.3 0 256 0C250.7 0 242.5 1.67 237.6 3.702l-192 80C27.7 91.1 16 108.6 16 127.1c0 257.2 189.2 384 239.1 384c51.1 0 240-128.2 240-384C496 108.6 484.3 91.1 466.5 83.71zM256 446.5l.0234-381.1c.0059-.0234 0 0 0 0l175.9 73.17C427.8 319.7 319 417.1 256 446.5z" /></svg>
                            <div className="ml-4 text-xl text-slate-700 dark:text-gray-400">
                                Compliance
                            </div>
                        </div>
                        <p className="leading-loose text-gray-500 dark:text-gray-200 text-md">
                            We are a HIPAA Compliant center, we value the security and privacy of all our interactions and adhere to all state and federal standards. We go beyond these standards with further certifications and protocols.
                        </p>
                    </div>
                </div>
                <div className="mb-4 text-center border-t-2 border-gray-400">
                    <h2 className="mt-8 text-base text-slate-700 dark:text-gray-200  font-semibold tracking-wide uppercase">
                        Make your business stand out by joining the leaders in boutique contact center services...
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Features